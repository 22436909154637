import { useRouter } from 'next/navigation';
import { RouteType } from 'features/routing/types';
import { useSelector } from 'react-redux';
import { settingsSelector } from 'features/redux/selectors/settings';
import { Language } from 'features/utils/routing';
import { compile } from 'path-to-regexp';
import queryString from 'query-string';

export default function useLocalizedRouterFreeway() {
  const router = useRouter();
  const settings = useSelector(settingsSelector);

  const push = (href: RouteType, query?: Record<string, any>, linkParams?: Record<string, any>) => {
    const token = `/${settings.language}-${settings.country}`;
    const pathToComileFirst = href.urls[settings.language as Language];
    const toPath =
      pathToComileFirst !== undefined ? compile(pathToComileFirst, { encode: encodeURIComponent }) : () => '404';

    const url = `${token}${toPath(linkParams)}${query ? `?${queryString.stringify(query)}` : ''}`;

    router.push(url);
  };

  return {
    push,
  };
}
