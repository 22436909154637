import { setFlexibleSearchOffset } from 'features/redux/actions/search';
import { searchInfoSelector } from 'features/redux/selectors/search';
import { Box } from 'ui/primitives/box';
import { useTypedSelector } from 'public-web/src/redux/store';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import tokens from 'ui/design/tokens/tokens';
import { Typography } from 'ui/primitives/typography';
import styled from 'styled-components';

interface FlexibleSearchButtonProps {
  offset: number;
  children: ReactNode;
}

function FlexibleSearchButton({ offset, children }: FlexibleSearchButtonProps) {
  const dispatch = useDispatch();
  const searchInfo = useTypedSelector(searchInfoSelector);
  const isActive = offset === searchInfo.flexibleSearchOffset;

  return (
    <Box
      $cursor="pointer"
      as="button"
      $px={tokens.spacing.spacing3Xl}
      $py={tokens.spacing.spacingM}
      $borderRadius={tokens.radius.radiusL}
      $background={isActive ? tokens.color.backgrounds.surfaceBg : tokens.color.buttons.buttonSecondary}
      $border="solid 2px"
      $borderColor={isActive ? tokens.color.border.borderHover : tokens.color.border.border}
      type="button"
      onClick={() => dispatch(setFlexibleSearchOffset(offset))}
    >
      <TextWrapper as="span" $fWeight="bold" $color={tokens.color.text.textPrimary}>
        {children}
      </TextWrapper>
    </Box>
  );
}

const TextWrapper = styled(Typography)`
  white-space: nowrap;
`;

export default FlexibleSearchButton;
