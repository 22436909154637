import { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  children: ReactNode;
  selector?: string;
}

export default function ClientOnlyPortal({ children, selector }: Props) {
  const portalRef = useRef<HTMLElement | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!selector) return;
    portalRef.current = document.querySelector<HTMLElement>(selector);
    setMounted(true);
  }, [selector]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!selector) return <>{children}</>;

  return mounted && portalRef.current ? createPortal(children, portalRef.current) : null;
}
