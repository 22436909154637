import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import { Trans } from '@lingui/react';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { searchInfoSelector } from 'features/redux/selectors/search';
import { hideModal } from 'features/redux/actions/settings';
import {
  setSearchAdultsCount,
  setSearchChildrenCount,
  setSearchDates,
  setSearchPlace,
} from 'features/redux/actions/search';
import { useCodebase } from 'features/hooks/useCodebase';
import PinIcon from 'ui/icons/ic_pin.svg';
import CalendarIcon from 'ui/icons/ic_calendar.svg';
import { Flex } from 'ui/primitives/flex';
import { SearchInfoReduxData } from 'features/redux/reducers/search';
import dayjs from 'dayjs';
import MapBoxAutocomplete from '../MapBoxAutocomplete';
import { ValueCleaner } from '../shared';
import PrimaryButton from '../../buttons/PrimaryButton';
import { mediaMdMax, mediaSmMax } from '../../../utils/breakpoints';
import Counter from '../../inputs/Counter';
import { ModalButtonHorizontalLine } from '../../modal/shared';
import TopBar from '../../modal/TopBar';
import { PlaceType } from '../utils/utils';
import { resolveInCodebase, resolveThemeValue } from '../../../design/utils';
import tokens from '../../../design/tokens/tokens';
import { Box } from '../../../primitives/box';
import FlexibleSearchButton from '../FlexibleSearchButton';
import FlexibleSearchButtonWithQuery from '../FlexibleSearchButtonWithQuery';

const DateRange: any = dynamic(import('../DateRange'));

interface Props {
  type?: string;
  popularPlaces: PlaceType;
  popularSearches: PlaceType;
  goToSearchPageWithResetFilters?: (query: any) => void;
  goToSearchPageWithQuery: (
    searchInfo: SearchInfoReduxData,
    searchDates: [],
    fromLandingPage?: boolean,
    winterVersion?: boolean
  ) => void;
}

function SearchWidgetModal({
  type,
  popularSearches,
  popularPlaces,
  goToSearchPageWithQuery,
  goToSearchPageWithResetFilters,
}: Props) {
  const dispatch = useDispatch();
  const isFWC = useCodebase('fwc');
  const searchInfo = useSelector(searchInfoSelector);
  const [showMoreType, setShowMoreType] = useState(type || null);
  const autoCompleteRef = useRef<HTMLInputElement>(null);
  const [placeError, setPlaceError] = useState(false);
  const [tmpSelectedDates, setTmpSelectedDates] = useState(searchInfo.selectedDays);
  const [inputHasFocus, setInputHasFocus] = useState(false);

  const FlexibleSearchButtonVariable =
    Router.route === '/search' ? FlexibleSearchButtonWithQuery : FlexibleSearchButton;

  const changeDateRange = (item: any) => {
    setTmpSelectedDates(item.selection);
  };

  const autoCompleteFocus = () => {
    setTimeout(() => {
      autoCompleteRef?.current?.focus();
    }, 200);
  };

  useEffect(() => {
    if (showMoreType === 'place') {
      autoCompleteFocus();
    }
  }, []);

  useEffect(() => {
    if (placeError) {
      autoCompleteFocus();
    }
  }, [placeError]);

  const confirmChange = () => {
    let fromLandingPage = true;

    if (isFWC) {
      goToSearchPageWithQuery(searchInfo, tmpSelectedDates, false, false);
      return;
    }

    if (!isFWC && (Router.route === '/search' || Router.route === '/')) {
      fromLandingPage = false;
    }

    goToSearchPageWithQuery(searchInfo, tmpSelectedDates, fromLandingPage, Router.route === '/');
    if (fromLandingPage) {
      dispatch(hideModal());
    }
    // tryCallDataLayerEvent(() => { trackSearchDataLayer(tmpSelectedDates, searchInfo.place); });
  };

  const clean = (typeLocal: 'place' | 'date' | 'persons') => {
    if (typeLocal === 'place') {
      dispatch(setSearchPlace(''));

      if (!isFWC && Router.route === '/search') {
        const currentQuery = Router.query;
        delete currentQuery.latitude;
        delete currentQuery.longitude;
        goToSearchPageWithResetFilters?.(currentQuery);
      }
    } else if (typeLocal === 'date') {
      dispatch(setSearchDates(null));
      setTmpSelectedDates(null);
      if (!isFWC && Router.route === '/search') {
        const currentQuery = Router.query;
        delete currentQuery.dateFrom;
        delete currentQuery.dateTo;
        goToSearchPageWithResetFilters?.(currentQuery);
      }
    } else if (typeLocal === 'persons') {
      dispatch(setSearchAdultsCount(0));
      dispatch(setSearchChildrenCount(0));

      if (!isFWC && Router.route === '/search') {
        const currentQuery = Router.query;
        delete currentQuery.adults;
        delete currentQuery.children;
        goToSearchPageWithResetFilters?.(currentQuery);
      }
    }
  };

  return (
    <BookingWidgetWrapper>
      {!isFWC && <TopBar heading={i18n._(t({ id: 'enjoymentStartsWithCampiri' }))} />}
      <ContentWrapper>
        <OneColumnWrapper
          onClick={() => {
            autoCompleteRef?.current?.focus();
          }}
        >
          <Place
            active={showMoreType === 'place'}
            className={`${placeError ? 'hasError' : ''} ${showMoreType === 'place' ? 'active' : ''}`}
            onClick={() => setShowMoreType('place')}
          >
            <Label>
              {isFWC && (
                <Box className="icon">
                  <PinIcon height={20} />
                </Box>
              )}
              <Trans id="autoComplete.startPlace.label" message="Odkud vyrazíte" />
            </Label>
            <InputValue active={showMoreType === 'place'}>
              <MapBoxAutocomplete
                popularPlaces={popularPlaces}
                popularSearches={popularSearches}
                inputHasFocus={inputHasFocus}
                hideClear={isFWC}
                setInputHasFocus={setInputHasFocus}
                selectedPlaceName={searchInfo?.place?.place_name ? searchInfo.place.place_name : null}
                inputRef={autoCompleteRef}
                isModal
                onSelect={(place: any) => {
                  setPlaceError(false);
                  dispatch(setSearchPlace(place));
                  setShowMoreType('date');
                }}
              />
            </InputValue>
          </Place>
        </OneColumnWrapper>
        <TwoColumnsWrapper>
          <Date
            className={`${tmpSelectedDates ? 'hasValue' : ''} ${showMoreType === 'date' ? 'active' : ''}`}
            onClick={() => setShowMoreType('date')}
          >
            <Label>
              {isFWC && (
                <Box className="icon">
                  <CalendarIcon height={20} />
                </Box>
              )}
              <Trans id="datePicker.term.label" message="Termín" />
            </Label>
            {tmpSelectedDates && showMoreType === 'date' && !isFWC && (
              <ValueCleaner
                onClick={() => {
                  clean('date');
                }}
              >
                <Trans id="button.labels.clear" message="Vymazat" />
              </ValueCleaner>
            )}
            <span>
              {tmpSelectedDates && tmpSelectedDates?.startDate && tmpSelectedDates?.endDate ? (
                `${dayjs(tmpSelectedDates.startDate).format('DD. MM.')} - ${
                  dayjs(tmpSelectedDates.endDate)?.format('DD. MM. YYYY') === 'Invalid Date'
                    ? dayjs(tmpSelectedDates.startDate).format('DD. MM. YYYY')
                    : dayjs(tmpSelectedDates.endDate)?.format('DD. MM. YYYY')
                }`
              ) : (
                <Trans id="datePicker.term.placeholder" message="Vyberte datum" />
              )}
            </span>
          </Date>
          {/* <VerticalLine />

          <Passengers
            className={`${searchInfo.children + searchInfo.adults > 0 ? 'hasValue' : ''} ${
              showMoreType === 'passengers' ? 'active' : ''
            }`}
            onClick={() => setShowMoreType('passengers')}
          >
            <Label>Počet osob</Label>
            {' '}
            {searchInfo.children + searchInfo.adults > 0 && showMoreType === 'passengers' && (
              <ValueCleaner
                onClick={() => {
                  clean('persons');
                }}
              >
                Vymazat
              </ValueCleaner>
            )}
            {searchInfo.children + searchInfo.adults > 0
              ? `${getFormattedPassengers(searchInfo.children + searchInfo.adults)}`
              : ' Zvolte počet'}
          </Passengers> */}
        </TwoColumnsWrapper>
        <ExpandedWrapper>
          <div style={{ display: showMoreType === 'place' ? 'block' : 'none' }} id="placesDropdown" />
          {showMoreType === 'date' && (
            <DateRange
              rangeColors={[isFWC ? '#001629' : '#119383']}
              minDate={isFWC ? dayjs().add(1, 'day').toDate() : new window.Date()}
              className="date-range-picker-modal"
              months={1}
              showMonthAndYearPickers={false}
              onChange={(item: any) => {
                changeDateRange(item);
                if (item.selection.endDate !== item.selection.startDate) {
                  // setShowMoreType('passengers');
                }
              }}
              moveRangeOnFirstSelection={false}
              ranges={
                tmpSelectedDates
                  ? [tmpSelectedDates]
                  : [
                      {
                        startDate: null,
                        endDate: new window.Date(''),
                        key: 'selection',
                      },
                    ]
              }
              direction={isFWC ? 'vertical' : 'horizontal'}
            />
          )}
          {showMoreType === 'passengers' && (
            <PassengersWrapper>
              <LineWrapper>
                <LabelPassengers>
                  <Trans id="labels.adults" message="Dospělí" />
                </LabelPassengers>
                <CounterWrapper>
                  <Counter
                    minimum={0}
                    disabled={false}
                    max={10}
                    value={searchInfo.adults}
                    name="adults"
                    onClick={(v: number) => {
                      dispatch(setSearchAdultsCount(v));
                    }}
                  />
                </CounterWrapper>
              </LineWrapper>
              <LineWrapper>
                <LabelPassengers>
                  <Trans id="labels.children" message="Děti" />
                </LabelPassengers>
                <CounterWrapper>
                  <Counter
                    minimum={0}
                    disabled={false}
                    max={10}
                    value={searchInfo.children}
                    name="children"
                    onClick={(v: number) => {
                      dispatch(setSearchChildrenCount(v));
                    }}
                  />
                </CounterWrapper>
              </LineWrapper>
            </PassengersWrapper>
          )}
        </ExpandedWrapper>
      </ContentWrapper>

      <ButtonWrapper>
        {isFWC && (
          <>
            <ModalButtonHorizontalLine />
            <FlexibleSearchButtonWrapper $px={tokens.spacing.spacingL} $gap={tokens.spacing.spacingL}>
              <FlexibleSearchButtonVariable offset={0}>
                <Trans id="flexibleSearch.exact" message="Exact date" />
              </FlexibleSearchButtonVariable>
              <FlexibleSearchButtonVariable offset={1}>
                ± <Trans id="flexibleSearch.1day" message="1 day" />
              </FlexibleSearchButtonVariable>
              <FlexibleSearchButtonVariable offset={2}>
                ± <Trans id="flexibleSearch.2days" message="2 days" />
              </FlexibleSearchButtonVariable>
            </FlexibleSearchButtonWrapper>
          </>
        )}

        <ModalButtonHorizontalLine />
        <SubmitCTAWrapper>
          <PrimaryButton onClick={confirmChange}>
            <Trans id="button.searchCaravan" message="Vyhledat karavan" />
          </PrimaryButton>
        </SubmitCTAWrapper>
      </ButtonWrapper>
    </BookingWidgetWrapper>
  );
}

const FlexibleSearchButtonWrapper = styled(Flex)`
  overflow-x: auto;
`;

const BookingWidgetWrapper = styled.div`
  padding: 0px;
  border-radius: 16px;
  border: none;
  background-color: var(--color-white);
  width: 100%;
  height: auto;
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  flex-direction: column;
`;

const InputValue = styled.div<{ active?: boolean }>`
  margin-top: 0px;
  font-family: var(--font-poppins);
  font-size: 20px;
  line-height: 1;
  color: var(--color-dark-grey);

  ${mediaSmMax} {
    font-size: 16px;
  }
  position: relative;
  width: 100%;

  ${resolveInCodebase(
    'fwc',
    css`
      width: auto;
      input {
        text-align: right;
      }
    `
  )}

  ${({ active }) =>
    !active &&
    css`
      width: max-content;

      input {
        font-style: normal;
        font-weight: 400;
        text-decoration-line: underline;
        text-align: right;
        @media screen and (-webkit-min-device-pixel-ratio: 0) {
          input {
            font-size: 16px;
          }
        }
      }
    `}
`;
const ButtonWrapper = styled.div`
  width: 100%;
  z-index: 10;
  position: fixed;
  bottom: 0px;
  padding-bottom: 16px;
  background: white;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding: 75px 0px;
  min-height: 85vh;
  ${mediaSmMax} {
    padding-bottom: 190px;
  }
  ${resolveInCodebase(
    'fwc',
    css`
      padding: ${resolveThemeValue(tokens.spacing.spacingS)} 0;
    `
  )}
`;

const SubmitCTAWrapper = styled.div`
  margin-top: 20px;
  width: calc(100% - 32px);
  margin: 0 auto;

  button {
    white-space: nowrap;
  }
`;

const ExpandedWrapper = styled.div`
  background-color: white;
  z-index: 9;
  cursor: pointer;
  width: 100%;
  ${resolveInCodebase(
    'fwc',
    css`
      border-top: 1px solid ${resolveThemeValue(tokens.color.border.border)};
      padding: ${resolveThemeValue(tokens.spacing.spacingL)};

      ${mediaMdMax} {
        .rdrMonthAndYearWrapper {
          display: none;
        }
      }
    `
  )}

  .rdrCalendarWrapper,
  .rdrMonth {
    width: 100%;
  }

  ${resolveInCodebase(
    'campiri',
    css`
      .rdrMonthName,
      .rdrDateDisplayWrapper {
        display: none;
      }
    `
  )}
`;

const OneColumnWrapper = styled.div`
  margin-top: -6px;
  width: 100%;
  border-bottom: solid 1px var(--color-beige);
  background-color: var(--color-white);
  display: flex;
  position: relative;
  z-index: 10;
  ${resolveInCodebase(
    'fwc',
    css`
      margin-top: 0;
      padding: 0 ${resolveThemeValue(tokens.spacing.spacingS)};
    `
  )}
`;

const TwoColumnsWrapper = styled(OneColumnWrapper)`
  border-top: solid 1px var(--color-beige);
  ${resolveInCodebase(
    'fwc',
    css`
      padding-bottom: ${resolveThemeValue(tokens.spacing.spacingS)};
    `
  )}
`;

const Date = styled.div`
  width: 100%;
  padding: 16px;
  font-family: var(--font-poppins);
  font-size: 16px;
  color: var(--color-dark-grey);
  cursor: pointer;
  position: relative;

  &.active {
    background-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceContrast, 'var(--color-beige)')};
    .icon svg {
      fill: #001629;
    }
  }

  &.hasValue {
    color: var(--color-dark-blue);
  }

  &.hasError {
    background: #f5d3d3;
  }

  ${resolveInCodebase(
    'fwc',
    css`
      padding: ${resolveThemeValue(tokens.spacing.spacingM)} ${resolveThemeValue(tokens.spacing.spacingS)};
      border-radius: ${resolveThemeValue(tokens.radius.radiusM)};
      display: flex;
      align-items: center;
      justify-content: space-between;
    `
  )}
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;

  color: var(--color-dark-blue);
  ${resolveInCodebase(
    'fwc',
    css`
      display: flex;
      align-items: center;
      margin-bottom: 0;
      gap: ${resolveThemeValue(tokens.spacing.spacingM)};
    `
  )}
`;
const Place = styled(Date)<{ active?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ active }) =>
    active &&
    css`
      flex-direction: column;
      align-items: self-start;
      ${resolveInCodebase(
        'fwc',
        css`
          align-items: center;
          flex-direction: row;
          .icon svg {
            fill: #001629;
          }
        `
      )}
    `}
  ${({ active }) =>
    !active &&
    css`
      ${Label} {
        margin-bottom: 0;
      }
    `}
`;

const PassengersWrapper = styled.div`
  padding: 16px;
`;

const LineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const CounterWrapper = styled.div`
  width: 130px;
`;

const LabelPassengers = styled.div`
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--color-dark-blue);
`;

export default SearchWidgetModal;
