import getConfig from "next/config";
import {
  Configuration,
  DynamicSegmentMarketplaceApi,
  DynamicSegmentVariationResult,
  LanguageCode,
} from "api/src";
import { useEffect, useState } from "react";
import { useCodebase } from "features/hooks/useCodebase";
import { useDebouncedCallback } from "use-debounce";
import { useSelector } from "react-redux";
import { getLanguageCodeForApi } from "../utils/language";
import { settingsSelector } from "features/redux/selectors/settings";

const { publicRuntimeConfig } = getConfig();

const getApiConfig = () =>
  new Configuration({
    basePath: publicRuntimeConfig.API_HOST.replace(/\/+$/, ""),
  });

const apiConfiguration = getApiConfig();

const DYNAMIC_SEGMENTS_API = new DynamicSegmentMarketplaceApi(
  apiConfiguration,
  apiConfiguration.basePath
);

export const getDynamicSegmentVariations = ({
  pageOffset,
  pageSize,
  dynamicSegmentId,
  search,
  language,
  searchableOnly,
}: {
  pageOffset?: number;
  pageSize?: number;
  dynamicSegmentId?: number;
  search?: string;
  language?: LanguageCode;
  searchableOnly?: boolean;
}) =>
  DYNAMIC_SEGMENTS_API.apiV1MarketplaceDynamicSegmentsVariationsGet(
    pageOffset,
    pageSize,
    dynamicSegmentId,
    search,
    language,
    searchableOnly
  );

export const useDynamicSegmentVariations = ({ search }: { search: string }) => {
  const [dynamicSegmentVariations, setDynamicSegmentVariations] = useState<
    DynamicSegmentVariationResult[]
  >([]);
  const isFWC = useCodebase("fwc");
  const { language } = useSelector(settingsSelector);

  const debouncedFetchDynamicSegmentVariations = useDebouncedCallback(
    async () => {
      const variations = await getDynamicSegmentVariations({
        search,
        pageSize: 3,
        searchableOnly: true,
        language: getLanguageCodeForApi(language),
      });
      setDynamicSegmentVariations(variations.data.items);
    },
    600
  );

  useEffect(() => {
    if (!isFWC) return;
    if (!search) {
      setDynamicSegmentVariations([]);
      return;
    }

    debouncedFetchDynamicSegmentVariations();
  }, [search]);

  return dynamicSegmentVariations;
};
