import React from 'react';
import styled, { css } from 'styled-components';
import { resolveInCodebase, resolveThemeValue } from '../../design/utils';
import tokens from '../../design/tokens/tokens';
import { mediaMdMax } from '../../utils/breakpoints';

interface Props {
  minimum: number;
  disabled?: boolean;
  disabledUp?: boolean;
  disabledDown?: boolean;
  max: number;
  value: any;
  register?: any;
  onClick: any;
  name: any;
}

const plusIcon = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 6.91765H6.98823V12H4.98824V6.91765H0V5.10588H4.98824V0H6.98823V5.10588H12V6.91765Z" fill="#1F2244" />
  </svg>
);

const minusIcon = (
  <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2H6.98823H4.98824H0V0H4.98824L6.98823 2.63189e-06L12 0V2Z" fill="#1F2244" />
  </svg>
);

export default function Counter({
  minimum,
  disabled = false,
  disabledUp = false,
  disabledDown = false,
  max,
  value,
  register,
  onClick,
  name,
}: Props) {
  const downDisabled = disabled || disabledDown || value <= minimum;
  const upDisabled = disabled || disabledUp || value >= max;
  return (
    <CounterWrapper>
      <CounterButton
        data-testid={`react-counter-${name || ''}-decrease-button`}
        disabled={downDisabled}
        onClick={() => (downDisabled ? null : onClick(parseInt(value, 10) - 1))}
        type="button"
      >
        {minusIcon}
      </CounterButton>
      <CounterLabel>
        <input min={1} name={name} type="hidden" value={value} ref={register || null} readOnly />
        <CounterLabelSpan data-testid={`react-counter-${name}-value`}>{value}</CounterLabelSpan>
      </CounterLabel>
      <CounterButton
        data-testid={`react-counter-${name || ''}-increase-button`}
        disabled={upDisabled}
        onClick={() => (upDisabled ? null : onClick(parseInt(value) + 1, 10))}
        type="button"
      >
        {plusIcon}
      </CounterButton>
    </CounterWrapper>
  );
}

export const CounterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${resolveInCodebase(
    'fwc',
    css`
      gap: ${resolveThemeValue(tokens.spacing.spacingM)};
      ${mediaMdMax} {
        justify-content: center;
      }
    `
  )}
  font-family: var(--var-font-poppins);
`;

const CounterButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 8px 6px;
  cursor: pointer;
  border-radius: 4px;
  border: solid 1px ${resolveThemeValue(tokens.color.border.border, 'var(--color-beige)')};
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    filter: grayscale(100%);
    opacity: 0.4;
  }
  &:focus {
    outline: 0;
  }
`;

const CounterLabel = styled.div`
  color: #17233c;
  text-align: center;
  border: none;
  display: flex;
`;

const CounterLabelSpan = styled.span`
  font-size: 18px;
  ${resolveInCodebase(
    'fwc',
    css`
      min-width: 18px;
    `
  )}
  font-weight: 500;
`;
