import React, { memo } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useMedia } from 'react-use';
import ToolTip from '../ToolTip';
import { sizes } from '../../utils/breakpoints';
import { getIconFromLocationSearchType, SearchLocationType } from './utils/utils';
import { resolveThemeValue } from '../../design/utils';
import tokens from '../../design/tokens/tokens';
import { staticBodyTextStyles } from '../primitives/typography/CustomTypography';

interface LocationItemProps {
  locationType: SearchLocationType;
  title: React.ReactNode | string;
  description: React.ReactNode | string;
  onClick: (e: any) => void;
  tooltipText?: React.ReactNode | string;
}

function LocationItem({ locationType, title, description, onClick, tooltipText }: LocationItemProps) {
  const isMobile = useMedia(`${sizes.width.SM.min}px`, false);
  return (
    <Wrapper onClick={onClick}>
      <IconWrapper>{getIconFromLocationSearchType(locationType)}</IconWrapper>
      <ContentWrapper>
        <CampiriBaseWrapper>
          <LocationTitle>{title}</LocationTitle>

          {locationType === SearchLocationType.CampiriBase && (
            <CampiriBaseBlock id="campiriBase_tooltip" onClick={(e) => e.stopPropagation()}>
              <CampiriBaseIcon className="icon icon-rv" />
              <ToolTip
                hoverTrigger={!isMobile}
                heading={i18n._(t({ id: 'labels.campiriBase' }))}
                text={tooltipText}
                closeIcon
                placementProp={isMobile ? 'bottom' : 'top'}
              >
                <CampiriBaseText>
                  <Trans id="search.item.campiriBase" message="Campiri base" />
                </CampiriBaseText>
              </ToolTip>
            </CampiriBaseBlock>
          )}
        </CampiriBaseWrapper>
        {description && <LocationDescription>{description}</LocationDescription>}
      </ContentWrapper>
    </Wrapper>
  );
}

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  background: ${resolveThemeValue(tokens.color.backgrounds.surfaceContrast, '#f4f2f2')};
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  padding: 8px 4px;
  gap: 12px;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  transition: background-color 0.3s;
  min-width: 320px;

  .isFWC & {
    padding: 8px;
  }

  &:hover {
    background-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceContrast, '#f4f2f2')};
    ${IconWrapper} {
      background: white;
      border: 1px solid ${resolveThemeValue(tokens.color.border.border)};
    }
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const LocationTitle = styled.h5`
  font-family: var(--font-poppins);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-dark-blue);
  .isFWC & {
    ${staticBodyTextStyles('mBold')};
    color: ${resolveThemeValue(tokens.color.text.textPrimary)};
  }
`;

const LocationDescription = styled.p`
  font-family: var(--font-poppins);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8e7e7f;

  .isFWC & {
    ${staticBodyTextStyles('s')};
    color: ${resolveThemeValue(tokens.color.text.textSecondary)};
  }
`;

const CampiriBaseWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CampiriBaseBlock = styled.div`
  background: #f9f8f6;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px;
`;

const CampiriBaseIcon = styled.i`
  font-size: 16px;
  color: #ff7e77;
`;

const CampiriBaseText = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #ff7e77;
  text-transform: capitalize;
`;

// eslint-disable-next-line import/prefer-default-export
export const MemorizedLocationItem = memo(LocationItem);
