import { LanguageCode } from 'api/src';
import { Language } from 'features/utils/routing';

export const getLanguageCodeForApi = (lang: Language) => {
  switch (lang) {
    case Language.cs:
      return LanguageCode.Czech;
    case Language.en:
      return LanguageCode.English;
    case Language.pl:
      return LanguageCode.Polish;
    case Language.sk:
      return LanguageCode.Slovak;
    case Language.es:
      return LanguageCode.Spanish;
    case Language.it:
      return LanguageCode.Italian;
    case Language.de:
      return LanguageCode.German;
    default:
      return LanguageCode.Czech;
  }
};
