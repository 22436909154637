import { Box } from 'ui/primitives/box';
import { ReactNode } from 'react';
import tokens from 'ui/design/tokens/tokens';
import { Typography } from 'ui/primitives/typography';
import styled from 'styled-components';
import { useFlexibleSearchOffsetQuery } from 'public-web/src/utils/query';

interface FlexibleSearchButtonProps {
  offset: number;
  children: ReactNode;
}

function FlexibleSearchButtonWithQuery({ offset, children }: FlexibleSearchButtonProps) {
  const [flexibleSearchOffset, setFlexibleSearchOffset] = useFlexibleSearchOffsetQuery();
  const isActive = offset === flexibleSearchOffset;

  return (
    <Box
      $cursor="pointer"
      as="button"
      $px={tokens.spacing.spacing3Xl}
      $py={tokens.spacing.spacingM}
      $borderRadius={tokens.radius.radiusL}
      $background={isActive ? tokens.color.backgrounds.surfaceBg : tokens.color.buttons.buttonSecondary}
      $border="solid 2px"
      $borderColor={isActive ? tokens.color.border.borderHover : tokens.color.border.border}
      type="button"
      onClick={() => setFlexibleSearchOffset(offset)}
    >
      <TextWrapper as="span" $fWeight="bold" $color={tokens.color.text.textPrimary}>
        {children}
      </TextWrapper>
    </Box>
  );
}

const TextWrapper = styled(Typography)`
  white-space: nowrap;
`;

export default FlexibleSearchButtonWithQuery;
