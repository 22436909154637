import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import styled, { css } from 'styled-components';
import getConfig from 'next/config';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Country, Language } from 'features/utils/routing';
import { capitalizeString } from 'features/utils/strings';
import { useSelector } from 'react-redux';
import { settingsSelector } from 'features/redux/selectors/settings';
import ClientOnlyPortal from 'features/providers/ClientOnlyPortal';
import { useCodebase } from 'features/hooks/useCodebase';
import useLocalizedRouterFreeway from 'features/routing/useLocalizedRouterFreeway';
import { routerDefinitions } from 'features/routing/routes/routerDefinitions';
import { DynamicSegmentVariationResult } from 'api/src';
import { mediaMdMax, mediaSmMax } from 'ui/utils/breakpoints';
import { useMedia } from 'react-use';
import { MemorizedLocationItem } from './LocationItem';
import { getSearchResultType, PlaceType, proximityCenter, SearchLocationType } from './utils/utils';
import { resolveInCodebase, resolveThemeValue } from '../../design/utils';
import tokens from '../../design/tokens/tokens';
import { useDynamicSegmentVariations } from './hooks/useSegmentVariations';
import { staticBodyTextStyles, staticHeadlineTextStyles } from '../primitives/typography/CustomTypography';
import { deviceMaxWidth } from '~/src/styles/breakpoints';

interface AutoCompleteProps {
  onSelect: any;
  inputRef: any;
  selectedPlaceName?: string;
  isModal?: boolean;
  inputHasFocus?: boolean;
  setInputHasFocus: any;
  dynamicSegmentVersion?: boolean;
  customClearSearchSideEffect?: () => void;
  popularPlaces: PlaceType;
  popularSearches: PlaceType;
  hideClear?: boolean;
}

const AutocompletePlace = React.memo(
  ({
    selectedPlaceName,
    onSelect,
    inputRef,
    isModal = false,
    inputHasFocus = false,
    setInputHasFocus,
    dynamicSegmentVersion = false,
    customClearSearchSideEffect,
    hideClear = false,
    popularSearches,
    popularPlaces,
  }: AutoCompleteProps) => {
    const localizedRouter = useLocalizedRouterFreeway();
    const settings = useSelector(settingsSelector);
    const isFWC = useCodebase('fwc');
    const [search, setSearch] = useState(selectedPlaceName || '');
    const [locationNotFound, setLocationNotFound] = useState(false);

    const searchDynamicSegments = useDynamicSegmentVariations({ search });

    useEffect(() => {
      if (searchDynamicSegments.length > 0) {
        setLocationNotFound(false);
      }
    }, [searchDynamicSegments.length]);

    useEffect(() => {
      setSearch(selectedPlaceName || '');
    }, [selectedPlaceName]);

    const [results, setResults] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showCleanOption, setShowCleanOption] = useState(false);
    const autocompleteRef = useRef<HTMLInputElement>(null);
    const isSelected = !locationNotFound && results.length === 0 && !!selectedPlaceName;
    const isMobile = useMedia(deviceMaxWidth.phone);

    useEffect(() => {
      const onClickOutside = () => {
        if (inputHasFocus) {
          if (setInputHasFocus) {
            setInputHasFocus(false);
          }
          setResults([]);
        }
      };
      const handleClickOutside = (event: any) => {
        if (isMobile) return;
        if (!inputHasFocus) return;
        if (isModal && results.length !== 0) {
          event?.target?.click();
        }
        if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
          onClickOutside();
        }
      };
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, [isModal, inputHasFocus, results.length]);

    const performSearch = useCallback(
      async (place: string) => {
        const language = settings.language === Language.en ? Language.en : `${settings.language},${Language.en}`;
        if (place === '') {
          setResults([]);
          setIsLoading(false);
          return undefined;
        }

        const fwcCountries = ['de', 'it'];
        const campiriCountries = ['pl', 'cz', 'sk', 'es'];

        const res = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${place}.json?`, {
          params: {
            proximity: proximityCenter[settings.country as Country],
            country: (isFWC ? fwcCountries : campiriCountries).join(','),

            language,
            types: ['region', 'place', 'district', 'country', 'address'].join(','),
            access_token: getConfig()?.publicRuntimeConfig?.MAPBOX_TOKEN,
          },
        });
        setResults(res.data?.features);
        if (res.data?.features?.length === 0) setLocationNotFound(true);
        setIsLoading(false);
        return res;
      },
      [settings.language]
    );

    const handleSearchChange = useCallback(
      async (value) => {
        setLocationNotFound(false);
        setSearch(value);
        setIsLoading(true);
        return performSearch(value);
      },
      [performSearch]
    );

    const handleItemClicked = useCallback(
      (place) => {
        setSearch(place.place_name);
        setResults([]);
        onSelect(place);
      },
      [onSelect]
    );

    const handleSearchAndSelect = useCallback(
      async (value) => {
        const res = await handleSearchChange(value);
        const place = res?.data?.features?.find((feature: any) => feature.place_name_en === value);
        if (place) {
          handleItemClicked(place);
          setInputHasFocus(false);
        }
      },
      [performSearch]
    );

    const clearSearch = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setSearch('');
      setLocationNotFound(false);
      setInputHasFocus(false);
      onSelect(null);
      setResults([]);

      customClearSearchSideEffect?.();
    };

    const getMyLocation = () => {
      setResults([]);
      setInputHasFocus(false);
      setSearch(t({ id: 'label.location.findOut' }));

      if (!navigator?.geolocation) {
        setSearch(t({ id: 'label.location.notFound' }));
        setLocationNotFound(true);
        return;
      }
      navigator?.geolocation.getCurrentPosition(
        (position) => {
          axios
            .get(
              `https://api.tiles.mapbox.com/v4/geocode/mapbox.places-v1/${position.coords.longitude},${
                position.coords.latitude
              }.json?access_token=${getConfig()?.publicRuntimeConfig?.MAPBOX_TOKEN}&language=en`
            )
            .then((response) => {
              onSelect(response.data.features[0]);
              setInputHasFocus(false);
            });
        },
        (error) => {
          console.error('ERROR', error);
          setSearch(t({ id: 'label.location.notFound' }));
          setLocationNotFound(true);
        },
        {
          enableHighAccuracy: false,
          timeout: 10000,
        }
      );
    };

    const handleSelectLocation = useCallback(
      (e, place) => {
        e?.stopPropagation();
        e.preventDefault();
        handleItemClicked(place);
        setInputHasFocus(false);
      },
      [handleItemClicked]
    );

    const handleSelectDynamicSegment = useCallback(
      (e, segment: DynamicSegmentVariationResult) => {
        e?.stopPropagation();
        e.preventDefault();
        setInputHasFocus(false);

        localizedRouter.push(routerDefinitions.dynamicSegmentNew, undefined, {
          id: segment.dynamicSegment?.id,
          slug: segment.slug,
        });
      },
      [localizedRouter]
    );

    return (
      <AutocompletePlaceWrapper
        id="autocomplete-list-location"
        ref={autocompleteRef}
        className={` ${dynamicSegmentVersion ? 'dynamicSegmentVersion' : ''}`}
      >
        {search && showCleanOption && !isFWC && !hideClear && (
          <ValueCleaner onClick={clearSearch}>
            <Trans id="button.labels.clear" />
          </ValueCleaner>
        )}
        <AutocompleteInput
          id="autocomplete_input"
          data-testid="location-input"
          onFocus={() => {
            setInputHasFocus(true);
            setShowCleanOption(true);
          }}
          onBlur={() => {
            if (inputHasFocus && (!search || isSelected)) return;
            setInputHasFocus(false);
          }}
          ref={inputRef}
          className={search ? 'hasValue' : ''}
          type="text"
          value={search}
          onChange={(e) => handleSearchChange(e?.target?.value)}
          placeholder={i18n._(t({ id: 'autoComplete.startPlace.placeHolder' }))}
          locationNotFound={locationNotFound}
          autoComplete="off"
        />

        <ClientOnlyPortal selector={isModal && isFWC ? '#placesDropdown' : undefined}>
          {search && (results.length > 0 || searchDynamicSegments.length > 0) && (
            <AutocompleteItemsList
              className={`${isModal ? 'isModal' : ''} ${dynamicSegmentVersion ? 'dynamicSegmentVersion' : ''}`}
            >
              {!isModal && (
                <AutocompleteItem
                  data-testid="my-location-dropdown-item"
                  className="myLocation"
                  key="loc3"
                  onClick={getMyLocation}
                >
                  <IconWrapper>
                    <MyLocationIcon className="icon-my-location" />
                  </IconWrapper>
                  <Trans id="search.myLocation" message="Moje poloha" />
                </AutocompleteItem>
              )}
              <CategoryList>
                {isFWC && searchDynamicSegments.length > 0 && (
                  <>
                    <SubCategoryTitle>
                      <Trans id="search.stations" message="Stations" />
                    </SubCategoryTitle>
                    {searchDynamicSegments.map((segment) => (
                      <MemorizedLocationItem
                        key={segment.id}
                        onClick={(e) => handleSelectDynamicSegment(e, segment)}
                        title={segment.title}
                        locationType={SearchLocationType.Station}
                        description={segment.content ?? ''}
                      />
                    ))}
                  </>
                )}
                {!isModal && (
                  <SubCategoryTitle>
                    <Trans id="search.searchResult" message="Search result" />
                  </SubCategoryTitle>
                )}
                {isLoading ? (
                  <AutocompleteItem className="AutocompletePlace-items">
                    <Trans id="general.loading" message="Načítám..." />
                  </AutocompleteItem>
                ) : (
                  results.map((place) => {
                    const location = getSearchResultType(place.place_name_en, popularSearches, popularPlaces);
                    return (
                      <MemorizedLocationItem
                        key={place.id}
                        onClick={(e) => handleSelectLocation(e, place)}
                        title={place.text}
                        locationType={location?.type ?? SearchLocationType.SearchResult}
                        tooltipText={location?.popupText}
                        description={place.place_name?.split(',').slice(1).join(', ')}
                      />
                    );
                  })
                )}
              </CategoryList>
            </AutocompleteItemsList>
          )}
          {inputHasFocus && (!search || isSelected) && (
            <AutocompleteItemsListWithPopularPlaces
              className={`${isModal ? 'isModal' : ''} ${dynamicSegmentVersion ? 'dynamicSegmentVersion' : ''}`}
            >
              <FlexCol>
                <AutocompleteItem
                  data-testid="my-location-dropdown-item"
                  className="myLocation noBorder"
                  key="loc2"
                  onClick={getMyLocation}
                >
                  <IconWrapper>
                    <MyLocationIcon className="icon-my-location" />
                  </IconWrapper>
                  <Trans id="search.myLocation" message="Moje poloha" />
                </AutocompleteItem>

                {popularPlaces?.[settings.country as Country]?.length > 0 && (
                  <CategoryList>
                    <SubCategoryTitle>
                      <Trans id="search.popularPlaces" message="Popular places" />
                    </SubCategoryTitle>
                    {popularPlaces?.[settings.country as Country]?.map((popularSearch) => (
                      <MemorizedLocationItem
                        key={`popular-search-${popularSearch.name}`}
                        onClick={() => handleSearchAndSelect(capitalizeString(popularSearch.name))}
                        title={popularSearch.title}
                        locationType={popularSearch.type}
                        description={popularSearch.description}
                        tooltipText={popularSearch.popupText}
                      />
                    ))}
                  </CategoryList>
                )}
              </FlexCol>
              {!isFWC && (
                <CategoryList>
                  <SubCategoryTitle>
                    <Trans id="search.popularSearch" message="Popular search" />
                  </SubCategoryTitle>
                  {popularSearches?.[settings.country as Country]?.map((popularSearch) => (
                    <MemorizedLocationItem
                      key={`popular-search-${popularSearch.name}`}
                      onClick={() => handleSearchAndSelect(capitalizeString(popularSearch.name))}
                      tooltipText={popularSearch.popupText}
                      title={popularSearch.title}
                      locationType={popularSearch.type}
                      description={popularSearch.description}
                    />
                  ))}
                </CategoryList>
              )}
            </AutocompleteItemsListWithPopularPlaces>
          )}
        </ClientOnlyPortal>
      </AutocompletePlaceWrapper>
    );
  }
);

AutocompletePlace.displayName = 'AutocompletePlace';
export default AutocompletePlace;

const AutocompletePlaceWrapper = styled.div`
  position: relative;

  &.dynamicSegmentVersion {
    input {
      border: 1px solid #edeae3;
      padding: 16px;
      border-radius: 8px;
      font-size: 16px;
      line-height: 24px;
      color: #9c8c8c;
    }
  }
`;

const AutocompleteItemsList = styled.ul`
  border-radius: 8px;
  box-shadow: 0 4px 32px 0 rgba(31, 34, 68, 0.2);
  background-color: white !important;
  position: absolute;
  z-index: 9999;
  left: -24px;
  top: 57px;
  ${resolveInCodebase(
    'fwc',
    css`
      top: 47px;
      left: -42px;
    `
  )}
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: initial;
  ${mediaSmMax} {
    display: none;
  }

  &.isModal {
    display: flex !important;
    position: relative;
    left: inherit;
    top: inherit;
    box-shadow: none;
    margin-top: 16px;
    margin-bottom: -12px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: -16px;
    width: calc(100% + 32px);
    padding: 12px 16px;
    ${resolveInCodebase(
      'fwc',
      css`
        margin-top: 0;
      `
    )}
  }

  &.dynamicSegmentVersion {
    background: white;
    box-shadow: none;
    width: 100%;
    ${mediaSmMax} {
      display: block;
    }
  }
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  padding: 10px;
  font-size: 20px;

  background-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceContrast, '#edeae3')};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AutocompleteItem = styled.li`
  list-style: none;
  font-family: var(--font-poppins);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${resolveThemeValue(tokens.color.text.textPrimary, '#1f2244')};
  border: none;
  padding: 10px 4px;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: background-color 0.3s;
  border-radius: 8px;
  cursor: pointer;

  .isFWC & {
    padding: 8px;
  }

  &:hover {
    background-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceContrast, 'var(--color-beige)')};
    ${IconWrapper} {
      background: white;
      border: 1px solid ${resolveThemeValue(tokens.color.border.border)};
    }
  }

  .isFWC & {
    ${staticBodyTextStyles('mBold')}
  }

  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

const AutocompleteInput = styled.input<{ locationNotFound?: boolean }>`
  border: none;
  font-family: var(--font-poppins);
  font-size: 20px;
  line-height: 1;
  background-color: transparent;
  height: 40px;
  width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;

  ${resolveInCodebase(
    'fwc',
    css`
      height: 37px;
    `
  )}

  &::placeholder,
  &:-webkit-input-placeholder {
    font-family: var(--font-poppins);
    font-size: 20px;
    line-height: 1;
    height: 60px;
    color: var(--color-dark-grey);
  }

  ${mediaSmMax} {
    font-size: 16px;
    height: 20px;

    &::placeholder {
      font-size: 16px;
    }
  }

  &.hasValue {
    color: var(--color-dark-blue);
    ${resolveInCodebase(
      'fwc',
      css`
        color: ${resolveThemeValue(tokens.color.text.textPrimary)};
      `
    )}
  }

  color: ${(props) => (props.locationNotFound ? '#ff5e55 !important' : 'red')};
`;

export const ValueCleaner = styled.div`
  position: absolute;
  right: -8px;
  top: -16px;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: var(--color-error-red);
  text-decoration: underline;

  ${mediaMdMax} {
    right: 0px;
  }
`;

const MyLocationIcon = styled.i``;

const SubCategoryTitle = styled.h3`
  margin: 0;
  font-family: var(--font-poppins);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #8e7e7f;

  .isFWC & {
    ${staticHeadlineTextStyles('h5')};
    color: ${resolveThemeValue(tokens.color.text.textPrimary)};
    grid-column: span 2;
  }
`;

const CategoryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  .isFWC & {
    display: grid;
    grid-template-columns: 1fr 1fr;

    ${mediaMdMax} {
      display: flex;
    }
  }
`;

const AutocompleteItemsListWithPopularPlaces = styled(AutocompleteItemsList)`
  flex-direction: row;
  gap: 40px;
  align-items: flex-end;
  ${mediaSmMax} {
    flex-direction: column;
    align-items: inherit;
    gap: 16px;
  }

  &.dynamicSegmentVersion {
    flex-direction: column;
    align-items: inherit;
    gap: 16px;
  }
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
